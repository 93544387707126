import React, { useRef } from "react";
import { useWindowDimensions } from "../components/utils";
import MoreLink from "../components/morelink";
import * as CONST from "../components/const";
import Swiper from "react-id-swiper";
import { Autoplay, Swiper as RealSwiper } from "swiper";
RealSwiper.use([Autoplay]);
import "swiper/swiper.min.css"
import Modal from "react-modal"
import X from "../images/club/modal/x.svg"

//import badminton from "../images/club/slider/badminton/1.png"
import baseball from "../images/club/slider/baseball/1.png"
import basketball from "../images/club/slider/basketball/1.png"
import climbing from "../images/club/slider/climbing/1.png"
import event from "../images/club/slider/event/1.png"
import fishing from "../images/club/slider/fishing/2.png"
import football from "../images/club/slider/football/1.png"
import golf from "../images/club/slider/golf/1.png"
//import program from "../images/club/program.jpg"
import sabani from "../images/club/slider/sabani/1.png"
import sailing from "../images/club/slider/sailing/1.png"
import ski from "../images/club/slider/ski/1.png"
import tennis from "../images/club/slider/tennis/1.png"
import touring from "../images/club/slider/touring/1.png"
import photo from "../images/club/slider/photo/1.png"
//import volleyball from "../images/club/slider/volleyball/1.png"

//import badminton_modal from "../images/club/modal/badminton/1.png"
import baseball_modal from "../images/club/modal/baseball/1_rcb.png"
import basketball_modal from "../images/club/modal/basketball/1_rcb.png"
import climbing_modal from "../images/club/modal/climbing/1_rcb.png"
import event_modal from "../images/club/modal/event/1.png"
import fishing_modal from "../images/club/modal/fishing/1_rcb.png"
import football_modal from "../images/club/modal/football/1_rcb.png"
import golf_modal from "../images/club/modal/golf/1.png"
//import program_modal from "../images/club/modal/program/1.png"
import sabani_modal from "../images/club/modal/sabani/1_rcb.png"
import sailing_modal from "../images/club/modal/sailing/1_rc.png"
import ski_modal from "../images/club/modal/ski/1_rc.png"
import tennis_modal from "../images/club/modal/tennis/1_rcb.png"
import touring_modal from "../images/club/modal/touring/1_rcb.png"
//import volleyball_modal from "../images/club/modal/volleyball/1.png"
import photo_modal from "../images/club/modal/photo/1_rc.png"

function Clubs() {

  Modal.setAppElement('body');

  const ref = useRef(null);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  const params = {
    slidesPerView: 1,
    spaceBetween: 5,
    slidesPerGroup: 1,
    loop: false,
    loopFillGroupWithBlank: true,
    speed: 1000,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 80
      }
    },
    on: {
      init: function () {
        const prev_btn = document.querySelector('.swiper-button-prev');
        const next_btn = document.querySelector('.swiper-button-next');
        prev_btn.classList.add('bg-black', 'rounded-full', 'hidden');
        next_btn.classList.add('bg-black', 'rounded-full');
        prev_btn.addEventListener("click", goPrev);
        next_btn.addEventListener("click", goNext);
      },
      reachBeginning: function () {
        const prev_btn = document.querySelector('.swiper-button-prev');
        prev_btn.classList.add('hidden');
      },
      reachEnd: function () {
        const next_btn = document.querySelector('.swiper-button-next');
        next_btn.classList.add('hidden');
      },
      fromEdge: function () {
        const prev_btn = document.querySelector('.swiper-button-prev');
        const next_btn = document.querySelector('.swiper-button-next');
        prev_btn.classList.remove('hidden');
        next_btn.classList.remove('hidden');
      },
    }
  }

  const clubList = [
    { catid: 1, title_en: 'Event', title_jp: 'イベント', message: '忘年会や旅行など、様々なイベントで社内交流を深めています！', img: event, img_modal: event_modal, isOpen: false, enabled: true, discription: '<p>忘年会や旅行、花見、親睦会などなど…。社員同士で楽しい時間を過ごしています！</p>' },
    { catid: 2, title_en: 'Baseball', title_jp: '野球部', message: '野球も未経験者が沢山！心底野球を楽しんでいます！地域リーグに参加し、試合数が多いのも魅力です。', img: baseball, img_modal: baseball_modal, isOpen: false, enabled: true, discription: '<p>部員は約20名で、3月～11月の期間に月1～2回くらいのペースで活動をしています。</p><p>春と秋には「サタデーカップ」という大会（4～5チームでの予選リーグ、勝ち進めば決勝トーナメント）に参加し、ナイスゲームを目指して頑張っています。チームのレベルアップのために練習する回数も増えました。試合に勝つことはもちろん、練習の成果がプレーに表れた時の充実感も楽しんでいます。</p><p>活動後は食事をしながら、野球談義や世間話をして更にリフレッシュしています！</p>' },
    { catid: 3, title_en: 'Football', title_jp: 'サッカー部', message: '活動は月に1～2回。神奈川県情報サービス産業協会のフットサル大会で、三位の成績を獲得しました！', img: football, img_modal: football_modal, isOpen: false, enabled: true, discription: '<p>みなさん！サッカーは好きですか？</p><p>そんな問いかけに「もちろん」と答える未経験者から経験者が、必死に１つのボールを追いかけて楽しんでいる。そんな部活です。</p><p>年に２～３回のフットサル大会への参加。</p>' },
    { catid: 4, title_en: 'Tennis', title_jp: 'テニス部', message: '未経験でも大丈夫！経験者が分かりやすく教えます。「楽しく上達」がモットーです。', img: tennis, img_modal: tennis_modal, isOpen: false, enabled: true, discription: '<p>初中級者中心の、テニスを楽しむ部活です！経験者が教えますから、未経験者もダブルスの試合を楽しめるようになりますよ。</p>' },
    { catid: 5, title_en: 'Golf', title_jp: 'ゴルフ部', message: '', img: golf, img_modal: golf_modal, isOpen: false, enabled: false, discription: '<p></p>' },
    { catid: 6, title_en: 'Ski & Snowboard', title_jp: 'スキー・スノーボード部', message: '圧雪、新雪、こぶ斜面。ボード派もスキー派も楽しみ方色々。', img: ski, img_modal: ski_modal, isOpen: false, enabled: true, discription: '<p>スキー、ボード、テレマーク、雪山大好き人間集まれ！</p><p>シーズンスポーツなので他のクラブと掛け持ちするメンバーがたくさんいます。 日帰りから二泊三日まで、バリエーションに富んだ企画を用意してお待ちしています！</p>' },
    { catid: 7, title_en: 'Fishing', title_jp: '釣り部', message: '海でのボート釣りをメインに様々な釣りに挑戦しています。釣果は皆で美味しくいただきます。', img: fishing, img_modal: fishing_modal, isOpen: false, enabled: true, discription: '<p>24フィートのボートで真鶴、葉山を拠点に一年中美味しい魚を求めて様々な釣りをしています。キスやカワハギからジグやルアーでの青物、水深300M以上の深場までアグレッシブに挑戦しており、釣行後の新鮮な魚料理も楽しみの一つになっています。</p>' },
    { catid: 8, title_en: 'Touring', title_jp: 'ツーリング部', message: '安全第一で、ある時はのんびりと、ある時は軽快に、景色やワインディングを楽しんでいます。', img: touring, img_modal: touring_modal, isOpen: false, enabled: true, discription: '<p>全ての道は、目的地に通じる。有名な言葉です。</p><p>ツーリングという趣味の楽しみの一つは、間違いなくオートバイという乗り物を自然の中でコントロールすることです。</p><p>オートバイを購入する、ピカピカに磨くという楽しみ方もあるでしょう。景色や旅先での名産品をたしなむという楽しみ方もあるでしょう。</p><p>でも楽しみ方はそれだけではありません。地図を見ながらツーリングのルートを計画するなんていう楽しみ方もあります。一緒にルートを計画しませんか？</p>' },
    { catid: 9, title_en: 'Sailing', title_jp: 'ヨット部', message: '体力派もロマン派もチームワークで楽しんでいます！', img: sailing, img_modal: sailing_modal, isOpen: false, enabled: true, discription: '<p>一緒に非日常的な時間を過ごしましょう。</p><p>セーリングクラブは、葉山、シーボニア、宜野湾をホームポートとしてセーリングクルーザヨットESPRiTに乗り、相模湾や沖縄にてセーリングを楽しんでいます。</p><p>経験、未経験者に係わらず、ヨットの先生が優しくいろんな事を教えてくれるので、楽しい時間が過ごせます。</p>' },
    // {catid: 10, title_en: 'Volleyball', title_jp: 'バレーボール部', message:'', img: volleyball, img_modal: volleyball_modal, isOpen:false, enabled:false, discription: '<p></p>'},
    // {catid: 11, title_en: 'Badminton', title_jp: 'バドミントン部', message:'', img: badminton, img_modal: badminton_modal, isOpen:false, enabled:false, discription: '<p></p>'},
    { catid: 12, title_en: 'Climbing', title_jp: '登山部', message: '6～9月の夏山を中心に、ハイキングや登山を楽しんでいます。', img: climbing, img_modal: climbing_modal, isOpen: false, enabled: true, discription: '<p>山歩きに興味をもった仲間が集まり、装備・体力に合わせたプランで新緑や雲海を探しに出かけています。</p><p>日常ではチョット出会えないような風景の中に入り込み、仲間と一緒にのんびりとした時間を楽しんでいます。</p><p>爽快な山の空気でリフレッシュできます！！</p>' },
    // {catid: 18, title_en: 'Program', title_jp: 'プログラミング部', message:'', img: program, img_modal: program_modal, isOpen:false, enabled:false, iscription: '<p></p>'},
    { catid: 19, title_en: 'Sabani', title_jp: 'サバニ部', message: '荒波の中を漕ぐ力と風の力だけで進む古式の船でチャレンジ！', img: sabani, img_modal: sabani_modal, isOpen: false, enabled: true, discription: '<p>「サバニ」とは琉球列島の漁師が主に使っていた伝統的な船。そのサバニを使って開催される「サバニ帆漕レース」にチャレンジ！みんなで声を掛け合って一心に漕ぐ。最高の仲間たちが待っています！！</p>' },
    { catid: 22, title_en: 'Basketball', title_jp: 'バスケットボール部', message: '経験未経験問わず、和気あいあいとバスケを楽しんでいます！', img: basketball, img_modal: basketball_modal, isOpen: false, enabled: true, discription: '<p>経験未経験、男女問わず、楽しく活動しています！</p><p>活動の主な流れは、練習を行ってから、チーム分けをして試合となっています。</p><p>経験者がしっかりフォローしますので、未経験者でも安心してバスケを楽しめる部活です！</p>' },
    { catid: 999, title_en: 'Photo', title_jp: 'フォトサークル', message: '皆で観光を楽しみながら、写真を撮って楽しんでいます！', img: photo, img_modal: photo_modal, isOpen: false, enabled: true, discription: '<p>カメラの種類、被写体問わず、皆で楽しく写真を撮りに各地を散策しています。</p><p>カメラのオート撮影だけではもったいない！色々なモードで撮影する楽しさと、撮影した写真を酒の肴に皆で楽しんでいます！</p>' },
  ];

  const [clubs, setClubs] = React.useState(clubList.filter(club => club.enabled));

  const closeModal = (targetClub) => () => {
    targetClub.isOpen = false;
    setClubs(Array.from(clubs));
  }

  const openModal = (targetClub) => () => {
    targetClub.isOpen = true;
    setClubs(Array.from(clubs));
  }

  const { width, height } = useWindowDimensions();
  const targetWidth = () => {
    return width >= 768;
  }
  // width - 100 + "px"
  const [widthSize] = React.useState(targetWidth() ? '50%' : '90%');
  const [paddingSize] = React.useState(targetWidth() ? '3rem' : '1rem');
  // const [topPosition] = React.useState(targetWidth() ? '-4.5rem' : '0rem');
  // const [rightPosition] = React.useState(targetWidth() ? '-4.5rem' : '0rem');
  const [topPosition] = React.useState(targetWidth() ? '2rem' : '0rem');
  const [rightPosition] = React.useState(targetWidth() ? '2rem' : '0rem');
  // const [overflow] = React.useState(targetWidth() ? 'visible' : 'auto');
  const [overflow] = React.useState(targetWidth() ? 'auto' : 'auto');

  // useEffect(() => {
  //   const onResize = () => {
  //     if (width >= 768) {
  //       setWidthSize('50%');
  //     } else {
  //       setWidthSize('90%');
  //     }
  //   }
  //   window.addEventListener('resize', onResize);
  //   return () => window.removeEventListener('resize', onResize);
  // }, []);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      width: widthSize,
      padding: paddingSize,
      position: 'relative',
      borderRadius: 'none',
      maxHeight: height,
      overflow: overflow,
    },
    overlay: {
      zIndex: 100,
      backgroundColor: 'rgba(37, 36, 36, 0.75)'
    }
    /*	content : {
        
      position: 'fixed',
      left: '50%',
      top: '50%',
      zIndex: '99',
      transform: 'translate(-50%, -50%)',
     
      boxSizing: 'border-box',
      padding: '32px',
      borderRadius: '8px',
      backgroundColor: '#fff',
  width                 : widthSize,
      },
      overlay: {
       
      position: 'fixed',
      left: '0',
      top: '0',
      right: '0',
      bottom: '0',
      zIndex: '9',
      backgroundColor: 'rgba(0, 0, 0, .65)',
      }*/
  };

  const closeButtonStyles = {
    cursor: 'pointer',
    top: topPosition,
    right: rightPosition,
    zIndex: 110,
    width: '80px',
    height: '80px'
  };

  function createLink(catid) {
    let link = '';
    switch (catid) {
      case 999:
        link = 'https://blog.alphawave.co.jp/photo/';
        break;
      default:
        link = 'https://blog.alphawave.co.jp/act?catid=' + catid;
    }
    return link;
  }

  function dispClubs(clubs) {
    return (
      clubs.map((club) => (
        <div key={club.catid}>
          <div onClick={openModal(club)} className="mb-10">
            <img className="w-full" alt="" src={club.img} />
            <p className="text-md sm:text-lg xl:text-md font-medium xl:font-normal pt-5 pb-2">{club.title_en}</p>
            <p className="sm:text-base xl:text-sm">{club.message}</p>
          </div>
          <Modal
            isOpen={club.isOpen}
            onRequestClose={closeModal(club)}
            style={customStyles}
            closeTimeoutMS={0}
          >
            <img className="w-full" alt="" src={club.img_modal} />
            <h5 className="text-md sm:text-lg xl:text-md py-5 ">{club.title_jp}</h5>
            <div className="sm:text-base xl:text-sm" dangerouslySetInnerHTML={{ __html: club.discription }} />
            <div className={CONST.SECTION_AREA_CLASS}>
              <div className="w-6/12 sm:w-7/12 md:w-6/12 lg:w-8/12 xl:w-9/12"></div>
              <MoreLink link_to={createLink(club.catid)} add_class="mt-10 w-full xs:w-6/12 sm:w-5/12 md:w-6/12 lg:w-4/12 xl:w-3/12" is_arrow_long="false" text="blog" />
            </div>
            {/* <MoreLink link_to={createLink(club.catid)} add_class="mt-10 sm:w-3/12 py-5 " is_arrow_long="false" text="blog" /> */}
            <div className="absolute" style={closeButtonStyles} onClick={closeModal(club)}><img alt="" src={X} /></div>
          </Modal>
        </div>
      ))
    );
  }

  if (targetWidth()) {
    return (
      <Swiper {...params} ref={ref}>
        {dispClubs(clubs)}
      </Swiper>
    );
  } else {
    let newClubList = [];
    clubList[0].enabled = false;
    Object.assign(newClubList, clubList);
    () => setClubs(newClubList);
    return (

      <React.Fragment>
        <h2 className={CONST.H2_CLASS + " ml-5 xs:ml-7 sm:ml-10 xl:p-10 mt-8 xl:mt-0"}> クラブ活動</h2>
        <div className="p-5 xs:p-7 sm:p-10 grid grid-cols-1 xs:grid-cols-2 gap-5 sm:gap-8 z-30">
          {dispClubs(clubs)}
        </div>
      </React.Fragment>
    );
  }
}

export default Clubs;