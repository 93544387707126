import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout";
import Clubs from "../../components/clubs";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";
import MoreLink from "../../components/morelink";
// import bg from "../../images/club/bg.png";
const bg_h = "../../images/club/bg_h.png";
const bg_v = "../../images/club/bg_v.png";
const kv_h = "../../images/club/kv_h.png";
const kv_v = "../../images/club/kv_v.png";
const bg_text_h = "../../images/club/bg_text.png";
const bg_text_v = "../../images/club/bg_text1.png";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        description="ALPHA WAVEのイベントとクラブ活動です。サッカー、テニス、スキー、スノーボード、釣り、ツーリング、セーリング、登山、サバニ、バスケットをご覧ください。"
        title="Club"
      />

      <section className={CONST.SECTION_PB_AREA_CLASS + " xl:mb-20 2xl:mb-40"}>
        <div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS + " xl:text-white"}>
          <p className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_TITLE_EN_CLASS}>Event & club activities <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>イベント・クラブ活動</span></p>
          <p className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_TITLE_EN_CLASS}>Event & club <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>イベント・クラブ</span></p>
        </div>
        <div className={CONST.KEY_VISUAL_LEFT_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS + " z-10"} src={kv_h} />
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS + " z-10"} src={kv_v} />
        </div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS}></div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS + " sm:text-lg xl:text-md sm:tracking-wider font-light sm:leading-12"}>
          <div>
            <h1 className={CONST.H1_CLASS + " font-light 3xl:mb-8 "}>社内イベント</h1>
            <p className="">忘年会や旅行など、様々なイベントで社内交流を深めています！</p>
            <MoreLink link_to="https://blog.alphawave.co.jp/act/" add_class="w-full xs:w-8/12 sm:w-6/12 xl:w-11/12 mt-16 " />
          </div>

        </div>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.CLUB_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.CLUB_BACK_GROUND_PATTERN_CLASS} src={bg_v} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={"hidden md:block " + CONST.EVENT_BACK_GROUND_TEXT_H_CLASS} src={bg_text_h} />
        {/* <div className={CONST.KV_BACK_GROUND_GLAY_AREA_CLASS}></div> */}
      </section>

      <section className={CONST.SECTION_PB_AREA_CLASS + " md:pt-40"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={"md:hidden " + CONST.EVENT_BACK_GROUND_TEXT_V_CLASS} src={bg_text_v} />
        <div className="swiper-container z-30">
          <Clubs />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
